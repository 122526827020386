// Generated by Framer (d03ec02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {ats2ZSP5u: {hover: true}, gzKGW1iIS: {hover: true}};

const cycleOrder = ["gzKGW1iIS", "ats2ZSP5u"];

const serializationHash = "framer-kIoSO"

const variantClassNames = {ats2ZSP5u: "framer-v-3maoih", gzKGW1iIS: "framer-v-1fhii58"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Social Icon Dark": "ats2ZSP5u", "Social Icon": "gzKGW1iIS"}

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, slhe62YRU: icon ?? props.slhe62YRU ?? "TwitterLogo", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gzKGW1iIS", wv7XyTlHT: link ?? props.wv7XyTlHT} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wv7XyTlHT, slhe62YRU, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "gzKGW1iIS", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><Link href={wv7XyTlHT}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1fhii58", className, classNames)} framer-m81slm`} data-framer-name={"Social Icon"} layoutDependency={layoutDependency} layoutId={"gzKGW1iIS"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"ats2ZSP5u-hover": {"data-framer-name": undefined}, "gzKGW1iIS-hover": {"data-framer-name": undefined}, ats2ZSP5u: {"data-framer-name": "Social Icon Dark"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1iql967-container"} layoutDependency={layoutDependency} layoutId={"uYT3bnyyz-container"}><Phosphor color={"var(--token-9cc1f5a3-0f9f-4ca5-8c33-9fea7c424bab, rgb(179, 189, 204)) /* {\"name\":\"Gray/75\"} */"} height={"100%"} iconSearch={"House"} iconSelection={slhe62YRU} id={"uYT3bnyyz"} layoutId={"uYT3bnyyz"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"} {...addPropertyOverrides({"ats2ZSP5u-hover": {color: "var(--token-e47fa55e-3968-4e03-b936-816fe655d59a, rgb(238, 240, 244)) /* {\"name\":\"Gray/95\"} */"}, "gzKGW1iIS-hover": {color: "var(--token-e47fa55e-3968-4e03-b936-816fe655d59a, rgb(238, 240, 244)) /* {\"name\":\"Gray/95\"} */"}, ats2ZSP5u: {color: "var(--token-3a86214f-2945-41e0-99a1-e6f8bf309f3d, rgb(10, 13, 16)) /* {\"name\":\"Gray/5\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-kIoSO[data-border=\"true\"]::after, .framer-kIoSO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kIoSO.framer-m81slm, .framer-kIoSO .framer-m81slm { display: block; }", ".framer-kIoSO.framer-1fhii58 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 24px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 24px; }", ".framer-kIoSO .framer-1iql967-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kIoSO.framer-1fhii58 { gap: 0px; } .framer-kIoSO.framer-1fhii58 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-kIoSO.framer-1fhii58 > :first-child { margin-left: 0px; } .framer-kIoSO.framer-1fhii58 > :last-child { margin-right: 0px; } }", ".framer-kIoSO.framer-v-3maoih.framer-1fhii58, .framer-kIoSO.framer-v-1fhii58.hover.framer-1fhii58 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 24px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ats2ZSP5u":{"layout":["fixed","fixed"]},"wr833qHt0":{"layout":["fixed","fixed"]},"Q9Mxc_kvZ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wv7XyTlHT":"link","slhe62YRU":"icon"}
 * @framerImmutableVariables true
 */
const Framerw_QskBpMr: React.ComponentType<Props> = withCSS(Component, css, "framer-kIoSO") as typeof Component;
export default Framerw_QskBpMr;

Framerw_QskBpMr.displayName = "Social Icons";

Framerw_QskBpMr.defaultProps = {height: 24, width: 24};

addPropertyControls(Framerw_QskBpMr, {variant: {options: ["gzKGW1iIS", "ats2ZSP5u"], optionTitles: ["Social Icon", "Social Icon Dark"], title: "Variant", type: ControlType.Enum}, wv7XyTlHT: {title: "Link", type: ControlType.Link}, slhe62YRU: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "TwitterLogo", hidden: undefined, title: "Icon"}} as any)

addFonts(Framerw_QskBpMr, [...PhosphorFonts])